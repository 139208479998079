import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: ".5rem",
      marginBottom: ".5rem",
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

export function MiniDivider() {
  const classes = useStyles();

  return <Divider className={classes.root} />;
}
