import { Theme } from "@material-ui/core";

export const HEADER_HEIGHT = "70px";
export const HEADER_PADDING_HEIGHT = "50px";

export const xPadding = (theme: Theme) => ({
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  [theme.breakpoints.up("lg")]: {
    paddingLeft: "4rem",
    paddingRight: "4rem",
  },
});

export const halfRoundedImage = (): any => ({
  halfRoundedImage: {
    borderRadius: "25%",
  },
});

export const boxShadowElevation1 = () => ({
  boxShadowElevation1: {
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
});

export const boxShadowElevation2 = () => ({
  boxShadowElevation2: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
  },
});

export const renderNewLines = (): any => ({
  renderNewLines: {
    whiteSpace: "pre-line",
  },
});

export const centeredText = (): any => ({
  centeredText: {
    textAlign: "center",
  },
});

export const centeredWithMargin = (): any => ({
  centeredWithMargin: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export const centeredWithFlex = (): any => ({
  centeredWithFlex: {
    display: "flex",
    justifyContent: "center",
  },
});

export const centeredImage = () => ({
  centeredImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export const pacificoFont = () => ({
  pacificoFont: {
    fontFamily: "Pacifico, cursive",
  },
});

export const montserratFont = () => ({
  montserratFont: {
    fontFamily: "Montserrat, sans-serif",
  },
});

export const openSansFont = () => ({
  openSansFont: {
    fontFamily: "Open Sans, sans-serif",
  },
});

export const fontSizeP9Rem = () => ({
  fontSizeP9Rem: {
    fontSize: ".9rem",
  },
});

export const fontSize1Rem = () => ({
  fontSize1Rem: {
    fontSize: "1rem",
  },
});

export const fontSize15Rem = () => ({
  fontSize15Rem: {
    fontSize: "1.5rem",
  },
});
