import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import { Box, makeStyles, Theme } from "@material-ui/core";
import {
  centeredWithMargin,
  fontSize15Rem,
  HEADER_HEIGHT,
  pacificoFont,
} from "../styles/Styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    backgroundColor: "#FFF",
    color: "#000",
    minHeight: HEADER_HEIGHT,
    maxHeight: HEADER_HEIGHT,
  },
  ...pacificoFont(),
  ...fontSize15Rem(),
  ...centeredWithMargin(),
}));

export function Header() {
  const scrollTrigger = useScrollTrigger();
  const classes: any = useStyles();

  return (
    <Slide appear={false} direction="down" in={!scrollTrigger}>
      <AppBar>
        <Box boxShadow={1}>
          <Toolbar className={classes.appBar}>
            <div
              className={clsx(
                classes.pacificoFont,
                classes.fontSize15Rem,
                classes.centeredWithMargin
              )}
            >
              can you adopt me
            </div>
          </Toolbar>
        </Box>
      </AppBar>
    </Slide>
  );
}
