import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  boxShadowElevation1,
  boxShadowElevation2,
  centeredImage,
  halfRoundedImage,
  montserratFont,
  openSansFont,
  pacificoFont,
  renderNewLines,
} from "../styles/Styles";
import { AnimalCardProps } from "./AnimalCard";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...pacificoFont(),
    ...openSansFont(),
    ...montserratFont(),
    ...renderNewLines(),
    ...centeredImage(),
    ...boxShadowElevation1(),
    ...boxShadowElevation2(),
    ...halfRoundedImage(),
    animalImage: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
    dialogRoot: {
      height: "75%",
    },
  })
);

interface AnimalDialogProps extends AnimalCardProps {
  setState: (state: any) => void;
  isOpen: boolean;
}

export function AnimalDialog(props: AnimalDialogProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isOpen);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleClose = () => {
    setOpen(false);
    props.setState({ loadingActive: false, learnMoreActive: false });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ className: classes.dialogRoot }}
      >
        <DialogTitle className={clsx(classes.boxShadowElevation1)}>
          <Typography
            gutterBottom
            variant="h5"
            className={clsx(classes.pacificoFont)}
          >
            <strong>{props.name}</strong>
          </Typography>
        </DialogTitle>
        <DialogContent className={"Scrollbar"}>
          <img
            src={props.image_url}
            width={"50%"}
            alt={props.name}
            className={clsx(
              classes.centeredImage,
              classes.animalImage,
              classes.halfRoundedImage
            )}
          />
          {/*<Trail open={props.isOpen}>*/}
          <DialogContentText
            className={clsx(classes.openSansFont, classes.renderNewLines)}
          >
            {props.description}
          </DialogContentText>
          {/*</Trail>*/}
        </DialogContent>
        <DialogActions className={clsx(classes.boxShadowElevation2)}>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            className={classes.openSansFont}
          >
            <strong>Okay</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
