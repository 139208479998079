import React, { useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  centeredText,
  fontSize1Rem,
  fontSizeP9Rem,
  montserratFont,
  openSansFont,
  pacificoFont,
} from "../styles/Styles";
import clsx from "clsx";
import { MiniDivider } from "./MiniDivider";
import LoadingBackdrop from "./LoadingBackdrop";
import { AnimalDialog } from "./AnimalDialog";
import { useMediaQuery } from "@material-ui/core";

export interface AnimalCardProps {
  id: string;
  name: string;
  breed: string;
  sex: string;
  weight: string;
  age: string;
  description: string;
  location: string;
  adoption_fee: string;
  image_url: string;
  original_url: string;
  species: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down("sm")]: {
        width: "125px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "200px",
      },
      backgroundColor: "#F9F5E3",
    },
    cardContent: {
      [theme.breakpoints.down("sm")]: {
        minHeight: "50px",
        maxHeight: "50px",
        padding: "10px",
      },
      [theme.breakpoints.up("lg")]: {
        minHeight: "140px",
        maxHeight: "140px",
      },
    },
    media: {
      [theme.breakpoints.down("sm")]: {
        height: "125px",
      },
      [theme.breakpoints.up("lg")]: {
        height: "200px",
      },
      width: "100%",
      backgroundSize: "contain",
    },
    cardActions: {
      minHeight: "75px",
      display: "flex",
      justifyContent: "center",
    },
    buttonText: {
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: ".6rem",
      },
    },
    hideTextWithEllipsis: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    name: {
      [theme.breakpoints.down("sm")]: {
        fontSize: ".85rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1.5rem",
      },
    },
    mobileButtons: {
      display: "flex",
      flexDirection: "column",
      padding: "10px",
      paddingTop: "0px",
    },
    mobileButtonPadding: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "5px",
      },
    },
    ...pacificoFont(),
    ...montserratFont(),
    ...openSansFont(),
    ...fontSizeP9Rem(),
    ...fontSize1Rem(),
    ...centeredText(),
  })
);

export function AnimalCard(props: AnimalCardProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState({
    loadingActive: false,
    learnMoreActive: false,
  });

  const onLearnMoreClick = () => {
    setState({ ...state, loadingActive: true });

    setTimeout(() => {
      setState((state) => ({ ...state, learnMoreActive: true }));
    }, 150);
  };

  const buttons = (
    <>
      <div className={classes.mobileButtonPadding}>
        <Button
          size={isPhone ? "small" : "medium"}
          color="primary"
          variant={"contained"}
          className={clsx(classes.montserratFont)}
          onClick={onLearnMoreClick}
        >
          <span className={classes.buttonText}>View</span>
        </Button>
      </div>
      <div>
        <Button
          size={isPhone ? "small" : "medium"}
          color="primary"
          variant={"contained"}
          className={clsx(classes.montserratFont)}
          target="_blank"
          rel="noopener"
          href={props.original_url}
        >
          <span className={classes.buttonText}>Adopt</span>
        </Button>
      </div>
    </>
  );

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image_url}
          title={props.name}
          onClick={onLearnMoreClick}
        />
      </CardActionArea>
      <CardContent className={clsx(classes.centeredText, classes.cardContent)}>
        <Typography
          className={clsx(
            classes.pacificoFont,
            classes.name,
            classes.hideTextWithEllipsis
          )}
        >
          {props.name}
        </Typography>

        <MiniDivider />

        {!isPhone && (
          <>
            <Typography
              className={clsx(classes.openSansFont, classes.fontSizeP9Rem)}
            >
              {props.sex}
            </Typography>

            <Typography
              className={clsx(classes.openSansFont, classes.fontSizeP9Rem)}
            >
              {props.age}
            </Typography>

            <Typography
              className={clsx(
                classes.openSansFont,
                classes.fontSizeP9Rem,
                classes.breed,
                classes.hideTextWithEllipsis
              )}
            >
              {props.breed}
            </Typography>
          </>
        )}
      </CardContent>

      {isPhone ? (
        <div className={clsx(classes.centeredText, classes.mobileButtons)}>
          {buttons}
        </div>
      ) : (
        <CardActions
          className={clsx(classes.centeredText, classes.cardActions)}
        >
          {buttons}
        </CardActions>
      )}
      <AnimalDialog
        {...props}
        setState={setState}
        isOpen={state.learnMoreActive}
      />

      {state.loadingActive && <LoadingBackdrop />}
    </Card>
  );
}
