// @ts-nocheck

import React from "react";
import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import "../styles/Parallax.css";
import { AnimalCards } from "./AnimalCards";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

const url = (name: string, wrap: boolean = false) =>
  `${
    wrap ? "url(" : ""
  }https://awv3node-homepage.surge.sh/build/assets/${name}.svg${
    wrap ? ")" : ""
  }`;

export function ParallaxBackground() {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Parallax pages={3}>
      <ParallaxLayer
        offset={0}
        speed={1}
        style={{ backgroundColor: "#FE938C" }}
      />
      <ParallaxLayer
        offset={1}
        speed={1}
        factor={isPhone ? 2 : 2}
        style={{ backgroundColor: "#F9DC5C" }}
      />
      <ParallaxLayer
        offset={2}
        speed={1}
        style={{ backgroundColor: "#87BCDE" }}
      />

      <ParallaxLayer
        offset={0}
        speed={0}
        factor={3}
        style={{
          backgroundImage: url("stars", true),
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <ParallaxLayer
        offset={1.3}
        speed={-0.3}
        style={{ pointerEvents: "none" }}
      >
        <img
          src={"./dog_treat.svg"}
          style={{ width: isPhone ? "30%" : "15%", marginLeft: "70%" }}
          alt={"Dog"}
        />
        {/*<div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/}
      </ParallaxLayer>

      <ParallaxLayer offset={0.2} speed={0.3} style={{ opacity: 0.4 }}>
        {/*  <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/}
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "22.5%" : "15%",
            marginLeft: "5%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={0.5} speed={1.1} style={{ opacity: 0.5 }}>
        {/*  <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/}
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "16.5%" : "11%",
            marginLeft: "85%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={0.75} speed={0.8} style={{ opacity: 0.3 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "9%" : "6%",
            marginLeft: "10%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={0.95} speed={0.5} style={{ opacity: 0.35 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "12%" : "8%",
            marginLeft: "80%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1} speed={0.8} style={{ opacity: 0.2 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "30%" : "20%",
            marginLeft: "55%",
          }}
          alt={"Heart Paw Print"}
        />
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "15%" : "10%",
            marginLeft: "15%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1.75} speed={0.5} style={{ opacity: 0.2 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "30%" : "20%",
            marginLeft: "70%",
          }}
          alt={"Heart Paw Print"}
        />
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "30%" : "20%",
            marginLeft: "40%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 0.4 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "15%" : "10%",
            marginLeft: "10%",
          }}
          alt={"Heart Paw Print"}
        />
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "30%" : "20%",
            marginLeft: "75%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      {/* Final Three */}
      <ParallaxLayer offset={1.6} speed={-0.1} style={{ opacity: 0.5 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "30%" : "20%",
            marginLeft: "60%",
          }}
          alt={"Heart Paw Print"}
        />
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "37.5%" : "25%",
            marginLeft: "30%",
          }}
          alt={"Heart Paw Print"}
        />
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "15%" : "10%",
            marginLeft: "80%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.7 }}>
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "30%" : "20%",
            marginLeft: "5%",
          }}
          alt={"Heart Paw Print"}
        />
        <img
          src={"./pawprints_heart.svg"}
          style={{
            display: "block",
            width: isPhone ? "22.5%" : "15%",
            marginLeft: "75%",
          }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isPhone ? 2.5 : 3.1}
        speed={-0.4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
        }}
      >
        <img
          src={"./earth.svg"}
          style={{ width: isPhone ? "110%" : "60%" }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      {/*<ParallaxLayer*/}
      {/*  offset={2}*/}
      {/*  speed={-0.3}*/}
      {/*  style={{*/}
      {/*    backgroundSize: "80%",*/}
      {/*    backgroundPosition: "center",*/}
      {/*    backgroundImage: url("clients", true),*/}
      {/*  }}*/}
      {/*/>*/}

      <ParallaxLayer
        offset={2.25}
        speed={-0.3}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"./house_paw.svg"}
          style={{ width: isPhone ? "30%" : "10%" }}
          alt={"Heart Paw Print"}
        />
        <span style={{ width: "20%" }} />{" "}
        {/*<div>Icons made by <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/}
        <img
          src={"./house_paw.svg"}
          style={{ width: isPhone ? "30%" : "10%" }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer
        offset={0}
        speed={0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />

      {/*<ParallaxLayer speed={0.7}>*/}
      {/*  <AnimalCards />*/}
      {/*</ParallaxLayer>*/}

      <ParallaxLayer
        speed={0.1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/*  <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>*/}
        <img
          src={"./dog_hugging_cat.svg"}
          style={{ width: isPhone ? "75%" : "40%" }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>

      <ParallaxLayer offset={1} speed={0.7}>
        <AnimalCards />
      </ParallaxLayer>

      <ParallaxLayer
        offset={isPhone ? 2.2 : 2.1}
        speed={-0}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={"./house_paw_colored.svg"}
          style={{ width: isPhone ? "45%" : "17.5%" }}
          alt={"Heart Paw Print"}
        />
      </ParallaxLayer>
    </Parallax>
  );
}
