import { createMuiTheme, Theme } from "@material-ui/core";

export const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#540D6E",
    },
    secondary: {
      main: "#6200B3",
    },
    background: {
      default: "#FFF",
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontFamily: "Open Sans, sans-serif",
        },
        body: {
          fontFamily: "Open Sans, sans-serif",
        },
      },
    },
  },
});
