import React, { useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { AnimalCard, AnimalCardProps } from "./AnimalCard";
import { CATS } from "../assets/Cats";
import { DOGS } from "../assets/Dogs";
import Pagination from "@material-ui/lab/Pagination";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import {
  centeredText,
  centeredWithFlex,
  centeredWithMargin,
} from "../styles/Styles";
import Select from "react-select";
import "../styles/ReactSelect.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      zIndex: 1,
    },
    cardsRoot: {
      flexGrow: 1,
      [theme.breakpoints.up("lg")]: {
        width: "75%",
        marginLeft: "auto",
        marginRight: "auto",
      },
      [theme.breakpoints.up("xl")]: {
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    filtersRoot: {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "3rem",
      },
      [theme.breakpoints.up("lg")]: {
        paddingBottom: "2rem",
        width: "50%",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    pagination: {
      paddingTop: "calc(70px + 2rem)",
      paddingBottom: "2rem",
    },
    ul: {
      "& .MuiPaginationItem-root": {
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "500",
        // color: "#fff",
      },
    },
    animalCardSizing: {
      [theme.breakpoints.down("sm")]: {
        padding: "20px !important",
      },
    },
    ...centeredWithFlex(),
    ...centeredText(),
    ...centeredWithMargin(),
  })
);

export const ANIMALS_COMBINED = CATS.concat(DOGS);
const ANIMALS_SHUFFLED = ANIMALS_COMBINED.sort(() => Math.random() - 0.5);

interface SelectValue {
  value: string;
  label: string;
}

export function AnimalCards() {
  const classes = useStyles();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageNumber, setPageNumber] = useState(1);
  // @ts-ignore
  const [animal, setAnimal] = React.useState(null as SelectValue);
  // @ts-ignore
  const [sex, setSex] = React.useState(null as SelectValue);
  // @ts-ignore
  const [breed, setBreed] = React.useState(null as SelectValue);

  const onPageChange = (event: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };
  const setValue = (genericSetter: (value: any) => void) => {
    return (value: any) => {
      genericSetter(value);
      setPageNumber(1);
    };
  };

  let FILTERED_ANIMALS: AnimalCardProps[] = [];
  ANIMALS_SHUFFLED.forEach((animalData: AnimalCardProps) => {
    if (
      !!animal &&
      animal.value !== "Either" &&
      animal.value !== animalData.species
    ) {
      return;
    }
    if (!!sex && sex.value !== "Either" && sex.value !== animalData.sex) {
      return;
    }
    if (!!breed && breed.value !== animalData.breed) {
      return;
    }

    FILTERED_ANIMALS.push(animalData);
  });

  let animalOptionsSet: Set<string> = new Set();
  let sexOptionsSet: Set<string> = new Set();
  let breedOptionsSet: Set<string> = new Set();

  FILTERED_ANIMALS.forEach((animalData: AnimalCardProps) => {
    animalOptionsSet.add(animalData.species);
    sexOptionsSet.add(animalData.sex);
    breedOptionsSet.add(animalData.breed);
  });

  const animalOptions = Array.from(animalOptionsSet.values()).map(
    (animalOption) => {
      return {
        label: animalOption,
        value: animalOption,
      };
    }
  );

  const sexOptions = Array.from(sexOptionsSet.values()).map((sexOption) => {
    return {
      label: sexOption,
      value: sexOption,
    };
  });

  const breedOptions = Array.from(breedOptionsSet.values()).map(
    (breedOption) => {
      return {
        label: breedOption,
        value: breedOption,
      };
    }
  );

  const NUM_ANIMALS_PER_PAGE = isPhone ? 8 : 12;
  const NUM_PAGES =
    FILTERED_ANIMALS.length % NUM_ANIMALS_PER_PAGE === 0
      ? Math.floor(FILTERED_ANIMALS.length / NUM_ANIMALS_PER_PAGE)
      : Math.floor(FILTERED_ANIMALS.length / NUM_ANIMALS_PER_PAGE + 1);

  return (
    <div className={classes.root}>
      <Pagination
        className={clsx(classes.centeredWithFlex, classes.pagination)}
        classes={{ ul: classes.ul }}
        count={NUM_PAGES}
        shape="rounded"
        size={isPhone ? "small" : "large"}
        color={"primary"}
        page={pageNumber}
        onChange={onPageChange}
      />

      <Grid
        container
        className={clsx(classes.filtersRoot)}
        justify="space-evenly"
        direction={"row"}
        alignItems="center"
        spacing={2}
      >
        <Grid item style={{ width: "150px" }}>
          <Select
            classNamePrefix={"React_Select"}
            placeholder={"Animal"}
            options={animalOptions}
            onChange={setValue(setAnimal)}
            isClearable
          />
        </Grid>

        <Grid item style={{ width: "150px" }}>
          <Select
            classNamePrefix={"React_Select"}
            placeholder={"Breed"}
            options={breedOptions}
            onChange={setValue(setBreed)}
            isClearable
          />
        </Grid>

        <Grid item style={{ width: "150px" }}>
          <Select
            classNamePrefix={"React_Select"}
            placeholder={"Sex"}
            options={sexOptions}
            onChange={setValue(setSex)}
            isClearable
          />
        </Grid>
      </Grid>

      <Grid
        container
        className={clsx(classes.cardsRoot)}
        direction="row"
        alignItems={"center"}
        justify="center"
        spacing={10}
      >
        {FILTERED_ANIMALS.slice(
          (pageNumber - 1) * NUM_ANIMALS_PER_PAGE,
          pageNumber * NUM_ANIMALS_PER_PAGE
        ).map((cat: AnimalCardProps) => {
          return (
            <Grid item key={cat.id} className={classes.animalCardSizing}>
              <AnimalCard {...cat} />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
