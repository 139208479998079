import React from "react";
import { Header } from "./components/Header";
import { ParallaxBackground } from "./components/ParallaxBackground";

// const useStyles = makeStyles((theme: Theme) => ({
//   app: {
//     marginTop: HEADER_HEIGHT,
//     paddingTop: HEADER_PADDING_HEIGHT,
//     ...xPadding(theme),
//   },
// }));

function App() {
  // const classes = useStyles();

  return (
    <div>
      <Header />
      <ParallaxBackground />
    </div>
    // <div className={clsx(classes.app)}>
    //   <Header />
    //   <AnimalCards />
    // </div>
  );
}

export default App;
